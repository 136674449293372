<template>
  <div
    v-if="!hasRolesAndPermissions"
    class="h-screen flex items-center justify-center"
  >
    <div class="text-2xl font-bold">
      You do not have any permissions here.
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Root',
  beforeMount() {
    var accountType;

    if (this.isCredpalAdmin) {
      accountType = process.env.VUE_APP_ADMIN_PATH;
    }else if (this.isCompanyAdmin) {
      accountType = process.env.VUE_APP_CORPORATE_PATH;
    }else if (this.isCompanyStaff) {
      accountType = process.env.VUE_APP_CUSTOMER_PATH;
    }else {
      console.log('else');
    }
    
    if (this.isSuperRisk && this.canAccess('loanbot-verifications') && this.isCredpalAdmin) {
      return this.$router.push({ name: 'loanbot-verifications', params: { accountType } });
    }
    this.$router.push({ name: 'dashboard', params: { accountType } });
  },
  computed: {
    ...mapState('session', ['roles', 'permissions']),
    hasRolesAndPermissions() {
      return !!this.permissions?.length && !!this.roles?.length;
    }
  }
}
</script>
